.feed {
  overflow-y: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 0; // Hide scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; // Hide scrollbar thumb
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // Hide scrollbar track
  }
  .imdiv {
    background-color: black;
    .image {
      height: 140;
    }
    .imageTile {
      height: 140;
      width: auto;
    }
  }
  .topic {
    padding-left: 16px;
  }
  .show_results {
    min-height: 70vh;
    display: grid;
    gap: 1rem;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
.card {
  max-width: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-image {
  width: 255px !important;
  height: 200px; // Adjust the height as needed
  object-fit: cover; // Ensures the image covers the container without distortion
  border-radius: 8px 8px 0 0; // Optional: adds rounded corners to the top of the image
}

.card-actions {
  display: flex;
  justify-content: center; // Center the button horizontally
  padding: 16px; // Adjust padding as needed
}

.card-button {
  background-color: #007bff !important; // Primary color
  color: #fff !important; // Text color
  text-transform: uppercase; // Capitalize button text
  font-weight: bold;
  width: 100%;
  padding: 8px 16px; // Adjust padding as needed
  border-radius: 4px; // Rounded corners
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional shadow effect
  transition: background-color 0.3s, transform 0.2s; // Smooth transitions for hover effects
  &:hover {
    background-color: #0056b3; // Darker shade on hover
    transform: scale(1.05); // Slightly enlarge button on hover
  }
}

@media (max-width: 768px) {
  .card {
    max-height: 420px;
    width: 100%;
    .card_content {
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.116);
    }
  }
}
