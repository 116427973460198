.nav {
  box-sizing: border-box;
  // background-color: #ff2200e8;
  padding-left: 60px;
  padding-right: 40px;
  width: 20%;
  height: 100vh;
  border-right: 2px solid rgba(0, 0, 0, 0.103);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 50px;
  .logo {
    display: flex;
    img {
      height: 70px;
      width: 255px;
      margin-left: -55px;
    }
  }
  .mobile_menu {
    display: none;
  }
  .menu {
    // background-color: aqua;
    // height: 28%;
    // display: flex;
    // flex-direction: column;
    // align-items: start;
    // justify-content: space-between;
    width: 110%;
    gap: 15px;

    color: black;
    .Link {
      width: 100%;
      text-decoration: none;
    }
    div {
      margin-top: 8px;
      margin-bottom: 8px;
      box-sizing: border-box;
      font-family: "Nunito", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      color: black;
      letter-spacing: 0.5px;
      font-style: normal;
      cursor: pointer;
      width: 100%;
      border-radius: 7px;
      padding: 3px 3px 3px 5px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      font-size: 18px;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transition: transform 0.3s ease, background-color 0.3s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }
      p {
        text-decoration: none;
        margin: 0;
      }
    }
  }
  .extra {
    width: 110%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 10%;
    .Link {
      width: 100%;
      text-decoration: none;
    }
    div {
      margin-top: 6px;
      margin-bottom: 8px;
      box-sizing: border-box;
      font-family: "Nunito", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      color: black;
      letter-spacing: 0.5px;
      font-style: normal;
      cursor: pointer;
      width: 105%;
      border-radius: 7px;
      padding: 3px 3px 3px 5px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      font-size: 18px;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transition: transform 0.3s ease, background-color 0.3s ease;
      p {
        text-decoration: none;
        margin: 0;
      }
    }
    .ab {
      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }
  .whatsapp {
    background-color: rgba(0, 0, 0, 0.185);
    border-radius: 50%;
    position: absolute;
    right: 12px;
    bottom: 80px;
  }
}

// bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb

@media (max-width: 768px) {
  .nav {
    height: auto;
    padding: 0;
    width: 100%;
    display: flex;
    bottom: 0;
    position: fixed;
    z-index: 100;
    background-color: white;
    // background-color: black;
    // background-color: rgba(255, 255, 255, 0.2);
    // backdrop-filter: blur(10px);
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    .logo {
      display: none;
    }
    .extra {
      display: none;
    }
    .menu {
      display: none;
    }
    .mobile_menu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav_icons {
        height: 6.1vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        // flex-direction: column;
      }
      .navicon {
        font-size: 25px;
      }
    }
  }
  .whatsapp {
    bottom: 100px !important;
  }
}
