.main {
  position: sticky;
  z-index: 10;
  top: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 9%;
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.103);

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    .inp {
      height: 40px;
      padding: 5px 10px 5px 10px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.055);
      // rgba(0, 0, 0, 0.103)
      height: 50%;
      width: 60%;
      margin-left: 25px;
      display: flex;
      align-items: center;
      border: 0.2px solid #ec1b228e;

      input {
        background-color: transparent;
        color: black;
        font-size: 20px;
        outline: none;
        border: none;
        padding: 3px;
        height: 100%;
        width: 100%;

        &::placeholder {
          color: black;
        }
      }
    }
    .admin_style {
      position: absolute;
      right: 20px;
      .navbar_right_account {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        border: 1px solid #bdb9b9;
        border-radius: 50%;
        gap: 10px;
        background-color: white;
        cursor: pointer;
      }
    }
  }
}
.disabled-input {
  pointer-events: none !important; /* Prevent user interaction */
  opacity: 0.5 !important; /* Make it look disabled */
  background-color: #f0f0f0 !important; /* Optional: Change background */
}
.logo {
  display: none;
}

// hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh

@media (max-width: 768px) {
  .header {
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      margin-left: 10px;
      margin-top: -5px;
    }
  }
  .admin_style {
    right: 0;
  }
}
