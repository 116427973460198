.product-details-container {
  display: flex;
  gap: 20px;
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .product-image-carousel {
    flex: 1;
    max-width: 500px;

    .carousel-image {
      img {
        width: 100%;
        height: auto;
        border-radius: 12px;
      }
    }
  }

  .product-details-content {
    overflow: hidden;
    flex: 1.5;
    padding: 20px;

    h1 {
      font-size: 28px;
      margin-bottom: 16px;
      color: #333;
    }

    .product-brand,
    .product-price {
      font-size: 20px;
      margin: 12px 0;
      color: #555;

      span {
        font-weight: bold;
        color: #e67e22;
      }
    }

    .product-description {
      text-align: justify;
      font-size: 16px;
      color: #777;
      margin-bottom: 20px;
      line-height: 1.5;
    }

    .product-video-wrapper {
      margin: 40px 0;
      padding: 30px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      .video-title {
        font-size: 24px;
        font-weight: 600;
        color: #2c3e50;
        margin-bottom: 20px;
        text-align: center;
      }

      .video-container {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        padding: 15px;
        background: #f5f7fa;
        border: 1px solid #eaeaea;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-3px); // Slight elevation on hover
        }

        iframe {
          width: 100%;
          height: 480px; // Maintain aspect ratio for larger screens
          border: none;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 24px;
  color: #555;
}
.main_cintainer {
  overflow-y: scroll;
  height: 100vh;

  .buttons-container {
    display: flex;
    justify-content: center; // Center-align the button within the container
    margin: 30px 0; // Add spacing around the button container

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 14px 36px;
      font-size: 18px; // Slightly larger font size for better readability
      font-weight: 600; // Bolder font weight
      border-radius: 8px;
      cursor: pointer;
      border: 2px solid transparent;
      color: #ffffff; // Text color
      background-color: #3498db; // Primary button color
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      transition: all 0.3s ease-in-out; // Smooth transition for hover effects

      // Button hover effect
      &:hover {
        background-color: #2980b9; // Slightly darker shade on hover
        border-color: #2980b9; // Change border color to match hover background
        transform: translateY(-2px); // Slight lift effect
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); // Enhanced shadow on hover
      }

      &:active {
        transform: translateY(0); // Remove lift effect on active
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow when clicked
      }

      // Focus state for accessibility
      &:focus {
        outline: none;
        border-color: #1a6ba1; // Border color to indicate focus
        box-shadow: 0 0 0 4px rgba(52, 152, 219, 0.2); // Glow effect on focus
      }

      svg {
        font-size: 20px; // Slightly larger icon size for better alignment
      }
    }
  }
}
// ffnnnnnnnnnnnnnnnnnnnnnnnnnnnccccccccccccccccccccccccccccccccccccccccccccccccnnnnnnnnnnnnnnnnnnnnnnnnnnnnn n n n n n nxmcm

@media (max-width: 768px) {
  .product-details-container {
    flex-direction: column;
    gap: 0;
    .product-image-carousel {
      width: 100%;
    }
    .product-details-content {
      .product-price {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }
  .product-video-wrapper {
    .video-title {
      margin-left: 10px;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
  .video-container {
    .frame {
      width: 100%;
      height: 300px;
    }
  }
}
