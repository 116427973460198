.footerarea {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.712);
  /* background: rgba(0, 62, 120, 0.9); */
}
.foot {
  height: auto;
}
footer {
  overflow: hidden;
  margin-top: 25px;
  color: white;
  height: 300px;
  // height: 100%;
  padding-bottom: 50px;
  background-image: url(https://content.jdmagicbox.com/comp/tirunelveli/f5/0462px462.x462.110204232126.l2f5/catalogue/v-l-new-bharath-marbles-palayamkottai-tirunelveli-tile-dealers-nj49j2.jpeg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
}
.copy {
  height: 40px;
  width: 100%;
  /* background: rgba(0, 62, 120, 0.9); */
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  ul {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
.area {
  display: flex;
  justify-content: space-around;
  border: transparent;
  padding-top: 50px;
  padding-bottom: 40px;
}
.f1 p {
  font-size: 13px;
  font-weight: 400;
  word-spacing: fit;
  line-height: 23px;
  word-wrap: break-word;
}
.f1 {
  width: 365px;
}

.f1 img {
  height: 48px;
  width: 190px;
}
footer h3 {
  font-size: 15px;
  color: #ec1b22;
  font-weight: 600;
  margin: 0px 0px 25px;
}
footer p {
  font-size: 13px;
  font-weight: 700;
}
.f2 {
  width: 180px;
}

.f2 p {
  margin-top: -9px;
}
.f3 .spann {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 25px;
}
.f3 p {
  margin-top: -9px;
}

.f4 p {
  margin-top: -9px;
}
.copy a {
  color: white;
}

.copy a:hover {
  text-decoration: underline;
}
.area a {
  text-decoration: none;
  color: white;
}
.area a:hover {
  color: #ffcc35;
}
.copy p {
  font-size: small;
}
.f2 i {
  color: #ffcc35;
  margin-right: 2px;
}
.f3 i {
  margin-right: 3px;
}
.f4 i {
  margin-right: 3px;
}
.pp {
  margin-left: 5px;
}
ul {
  margin-bottom: -6px;
  list-style: none;

  li {
    margin-right: 5px;
  }
}
.copy i {
  color: white;
  margin: 5px;
}
.imagg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 69px;
  width: 245.5px;
}
.imagg img {
  z-index: 100;
  height: 140px;
  width: 180px;
  margin-bottom: -25px;
}
.image_div {
  overflow: hidden;
  width: 190px;
  height: 49px;
  border-radius: 10px;
}
.footer_p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.pp {
  margin-bottom: 10px;
}
.location_p {
  margin-left: -10px;
}
.copy_para {
  color: rgb(0, 81, 255) !important;
}

// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

@media (max-width: 768px) {
  .area {
    padding-left: 20px;
    flex-direction: column;
    gap: 20px;
  }
  footer {
    // height: 71.2vh;
    height: 655px;
  }
  .image_div {
    margin-bottom: 10px;
  }
  .spann {
    margin-bottom: 13px !important;
  }
  .copy {
    justify-content: center;

    p {
      display: none;
    }
    ul {
      gap: 30px;
    }
  }
}
