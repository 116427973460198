.filter {
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .head {
    box-sizing: border-box;
    height: 9%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.103);
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;

    span {
      font-weight: 700;
      font-size: 18px;
      color: #333;
    }

    svg {
      color: #3f51b5;
      font-size: 24px;
    }
    .close {
      display: none;
    }
  }

  .body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adds space between elements */
    overflow-y: auto; /* Allows scrolling if content overflows */
    flex: 1; /* Takes the remaining height below the header */

    .slider {
      margin-top: 17px;
      width: 80%;
    }

    /* Slider and Price Range Styling */
    .slider-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .price-range {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #333;
      }

      .MuiSlider-root {
        color: #3f51b5;
      }
    }

    /* Apply Filter Button Styling */
    .filter-button {
      display: flex;
      justify-content: center;

      button {
        width: 100%;
        padding: 10px;
        background-color: #3f51b5;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #2c3e8c;
        }
      }
    }

    /* Applied Range Styling */
    .applied-range {
      font-size: 14px;
      color: #666;
      text-align: center;
      margin-top: 10px;
    }

    /* Calculator Styling */
    .calculator {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 15px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      h4 {
        font-size: 16px;
        color: #3f51b5;
        font-weight: 800;
        margin-bottom: 5px;
      }

      .input-group {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-size: 14px;
          font-weight: 400;
          // color: #666;
          color: black;
        }

        input {
          padding: 8px 10px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          font-size: 14px;
        }
      }

      .total-price {
        font-size: 16px;
        color: #333;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}
.slider .MuiSlider-thumb {
  display: none; /* Removes the dots at each side */
}

.slider .MuiSlider-track {
  background-color: #3f51b5; /* Keep the color of the slider */
}

.slider .MuiSlider-rail {
  background-color: #ddd; /* Customize the rail color if needed */
}
.MuiCheckbox-root {
  color: #3f51b5; // Customize color
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #4caf50; // Custom color when checked
}
.check {
  display: flex;
  align-items: center;
}
.applied-range {
  font-size: 1px;
  font-weight: 600;
}

// hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh

@media (max-width: 768px) {
  .filter {
    display: flex;
    position: absolute;
    width: 100%;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }
  .upper {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
  }
  .set_btn {
    width: 100%;
  }
  .head {
    .close {
      display: flex !important;
      position: absolute;
      right: 0;
      margin-right: 20px;
    }
  }
  .applied-range {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px;
  }
  .calculator {
    margin-bottom: 20px;
  }
}
