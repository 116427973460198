.App {
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  display: flex;
}
.mid_part {
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  border-right: 2px solid rgba(0, 0, 0, 0.103);
}

.mid_part::-webkit-scrollbar {
  width: 0;
}

.mid_part::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.mid_part::-webkit-scrollbar-track {
  background-color: transparent;
}
.filter_part {
  width: 20%;
}

@media (max-width: 768px) {
  /* Adjust layout for tablets and mobile */
  .App {
    align-items: center; /* Center content */
    height: -webkit-fill-available;
    height: 100vh;
    /* position: fixed; */
  }
  .mid_part {
    width: 100% !important;
    height: -webkit-fill-available;
    height: 100vh !important;
    /* position: sticky; */
  }
  .filter_part {
    display: none;
  }
}
