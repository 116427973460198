.about {
  .content {
    padding: 20px;
  }
}

.card_container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px; // Maintain a tight gap for compact layout
  justify-content: center;
  padding: 16px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .card-img-top {
    width: auto;
    height: 180px;
  }

  .card-body {
    padding: 0.75rem;

    .card-title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .card-text {
      margin-bottom: 0.75rem;
      font-size: 0.875rem;
      color: #666;
    }

    .btn-primary {
      background-color: #007bff;
      border: none;
      padding: 0.4rem 0.75rem;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      border-radius: 0.25rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
