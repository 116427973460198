.admin-home {
  background-color: #f8f9fa;
  min-height: 100vh;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .search-bar {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    input {
      width: 70%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 6px;
      font-size: 16px;
      margin-right: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
      transition: box-shadow 0.3s ease;

      &:focus {
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
      }
    }

    button {
      padding: 12px 24px;
      background-color: #1976d2;
      color: white;
      border: none;
      border-radius: 6px;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        background-color: #1565c0;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .results-container,
  .search-results {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    margin-bottom: 40px;
  }

  h3 {
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 26px;
    text-align: center;
  }

  /* Loading spinner */
  .MuiCircularProgress-root {
    margin-top: 50px;
  }

  p {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-top: 20px;
  }
}

.card {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #ddd;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  .card-image {
    height: 220px;
    object-fit: cover;
    border-bottom: 1px solid #eee;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #333;
    }

    .price {
      font-size: 20px;
      font-weight: bold;
      color: #1976d2;
    }
  }

  .card-actions {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid #eee;

    .card-button {
      background-color: #1976d2;
      color: white;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 24px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #1565c0;
      }
    }

    .MuiIconButton-root {
      color: #1976d2;
      position: static;
      bottom: 50px;
      padding: 8px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(25, 118, 210, 0.1);
        border-radius: 50%;
      }
    }

    .MuiButton-root {
      color: #d32f2f;
      border-radius: 6px;
      transition: background-color 0.3s ease;
      background-color: rgba(211, 47, 47, 0.1);

      &:hover {
        background-color: rgba(211, 47, 47, 0.233);
      }
    }
  }
}
.edit_icon {
  position: absolute;
  top: -225px;
  right: -225px;
  .edit {
    background-color: rgba(0, 0, 0, 0.384);
    color: white;
    padding: 2px;
  }
}
.delete_icon {
  background-color: rgba(211, 47, 47, 0.1);
}
// llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll

@media (max-width: 768px) {
  .edit_icon {
    position: absolute;
    top: -225px;
    right: -250px;
  }
  .contents {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
