.home {
  overflow-y: scroll;
  height: 100vh;
  height: -webkit-fill-available;
  &::-webkit-scrollbar {
    width: 0; // Hide scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; // Hide scrollbar thumb
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // Hide scrollbar track
  }

  .mari {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    font-weight: 600;
    marquee {
      height: 35px;
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
}

.adv {
  margin-right: 15px;
}
.adv h2 {
  font-size: 27px;
  font-weight: 750;
  color: #004d40;
  /* color: #1c437e; */
}
.adv h2 span {
  color: black;
}
.adv .sp {
  width: 80px;
  height: 3px;
  background: #ffcc35;
  margin-bottom: 10px;
}
.adv p {
  font-size: smaller;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  line-height: 22px;
  word-spacing: 1px;
  font-weight: 530;
  color: #313131;
}
.categories {
  padding: 15px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  .outer {
    cursor: pointer;
    width: 200px;
    padding: 4px;
    border: 0.2px solid #ec1b228e;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .inner {
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    background-color: rgba(0, 0, 0, 0.055);
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
    span {
      font-weight: 700;
    }
  }
}
.show_results {
  min-height: 380px;
  display: grid;
  gap: 1rem;
  padding: 1rem;
  padding-left: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  .imdiv {
    background-color: black;
    .image {
      height: 140;
    }
    .imageTile {
      height: 140;
      width: auto;
    }
  }
}
.categories .outer.selected .inner {
  // border: 2px solid #ffcc35; /* Highlight the selected category */
  background-color: rgba(0, 0, 0, 0.185);
}
.card_content {
  display: flex;
  gap: 10px;
}
.card {
  max-width: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-image {
  width: 255px !important;
  height: 200px; // Adjust the height as needed
  object-fit: cover; // Ensures the image covers the container without distortion
  border-radius: 8px 8px 0 0; // Optional: adds rounded corners to the top of the image
}

.card-actions {
  display: flex;
  justify-content: center; // Center the button horizontally
  padding: 16px; // Adjust padding as needed
}

.card-button {
  background-color: #007bff !important; // Primary color
  color: #fff !important; // Text color
  text-transform: uppercase; // Capitalize button text
  font-weight: bold;
  width: 100%;
  padding: 8px 16px; // Adjust padding as needed
  border-radius: 4px; // Rounded corners
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional shadow effect
  transition: background-color 0.3s, transform 0.2s; // Smooth transitions for hover effects
  &:hover {
    background-color: #0056b3; // Darker shade on hover
    transform: scale(1.05); // Slightly enlarge button on hover
  }
}
.carousel_image {
  height: 400 !important;
}
.outer {
  position: relative;
  display: inline-block;
}

.tooltip-box {
  position: absolute;
  top: -30px; /* Adjust to position tooltip above the div */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0.9;
  z-index: 1;
  pointer-events: none;
}

.outer:hover .tooltip-box {
  display: block;
}

// nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn

@media (max-width: 768px) {
  .home {
    width: 100%;
  }
  .carousel_div {
    height: auto;
    width: 100%;
  }
  .categories {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    // gap: 20px;
    justify-content: center; /* Centers horizontally */
    align-content: center;
  }
  .adv {
    margin: 0;
    width: 100%;
  }
  .mari {
    width: 100%;
  }
  .carousel_image {
    height: 200 !important;
  }
  .outer {
    margin-left: 10px;
    cursor: pointer;
    height: 45px;
    width: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .inner {
    flex-direction: column;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
    span {
      max-width: 50px !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      font-weight: 600 !important;
      font-size: 14px;
      position: absolute;
      bottom: -21px;
    }
  }
  .show_results {
    padding: 1rem;
  }
  .card {
    max-width: 100%;
    .card_content {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 20px;
    }
  }
}
