* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f9f9f9;
}

.form {
  max-width: 800px; // Set a max width for the form
  margin: 50px auto; // Center align form
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e1e1e1;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .box {
      display: flex;
      flex-direction: column;
      gap: 10px;

      label {
        font-size: 16px;
        font-weight: 600;
        color: #444;
      }

      input,
      select,
      textarea {
        border: 1px solid #ddd;
        padding: 12px 16px;
        border-radius: 8px;
        font-size: 16px;
        transition: border-color 0.3s, box-shadow 0.3s;
        outline: none;

        &:focus {
          border-color: #339af0;
          box-shadow: 0 0 8px rgba(51, 154, 240, 0.2);
        }
      }
    }

    .radio_box {
      background-color: #f8f8f8;
      padding: 16px 20px;
      border-radius: 8px;

      p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .radioBox {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 12px;

        .radio {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid transparent;
          transition: background-color 0.3s, border-color 0.3s;

          input {
            cursor: pointer;
          }

          span {
            font-size: 16px;
            cursor: pointer;
          }

          &:hover {
            background-color: #eef4fa;
            border-color: #339af0;
          }
        }
      }
    }

    .image_box {
      border: 2px dashed #bbb;
      border-radius: 12px;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #666;
        svg {
          margin-bottom: 8px;
          font-size: 48px;
          color: #339af0;
        }

        &:hover {
          color: #339af0;
          text-decoration: underline;
        }
      }
    }

    .image-previews {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .image-list {
        width: 100%;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 10px));
      }
      .image-item {
        position: relative;
        width: 150px;
        height: 100px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #ddd;
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        button {
          position: absolute;
          top: 8px;
          right: 8px;
          background-color: #ec1b22;
          color: white;
          border: none;
          border-radius: 50%;
          padding: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #d31117;
          }
        }
      }
    }

    .necessary {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .box {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 16px;

        label {
          min-width: 100px;
        }

        input {
          flex: 1;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        background-color: #339af0;
        color: white;
        font-size: 18px;
        font-weight: 600;
        padding: 16px 32px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s;

        &:hover {
          background-color: #287bbd;
          box-shadow: 0 4px 12px rgba(51, 154, 240, 0.4);
        }
      }
    }
  }
}
// gggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg

@media (max-width: 768px) {
  .image-list {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 2px)) !important;
  }
}
