/* Center the login box */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  margin: 0;
}

/* Style the box itself */
.login-box {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

/* Headings inside the login box */
.login-box h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

/* Paragraph below heading */
.login-box p {
  margin-bottom: 20px;
  color: #666;
  font-size: 0.9em;
}

/* Input field group */
.input-group {
  position: relative;
  margin-bottom: 15px;
}

.input-group input {
  width: calc(100% - 40px); /* Adjust width to make space for the icon */
  padding: 10px;
  padding-left: 40px; /* Add padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

/* Icon styles */
.input-icon {
  position: absolute;
  left: 30px;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
  color: #666;
  font-size: 1.2em; /* Adjust size if needed */
}

/* Style for password visibility icon */
.password-container {
  position: relative;
}

.password-container input {
  width: calc(100% - 40px); /* Adjust width to make space for the icon */
}

.password-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  font-size: 1.2em; /* Adjust size if needed */
}

/* Sign In button */
.login-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #357ae8;
}

/* Icon container for login page */
.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
