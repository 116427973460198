.admin {
  height: 100vh;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0; // Hide scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; // Hide scrollbar thumb
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // Hide scrollbar track
  }
  section {
    position: sticky;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    .select {
      font-size: large;
      font-weight: 700;
      cursor: pointer !important;
      // border-bottom: 2px solid #ec1b22;
    }
  }
}
